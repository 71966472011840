body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
p {
  margin: 0;
}

section {

  padding: 0 20px 20px 0;

  &>* {
    padding: 20px;
  }

  &.odd {
    background-color: #FFF;
    color: #000;
  }

  &.even {
    background-color: #000;
    color: #FFF;

    a {
      color: #5379cb;
    }
  }
}

.header {
  text-align: left;
  background-color: #1d3261;
  color: #F2f2f2;

  h1 {
    padding: 40px 0 10px 20px;
  }

  h3 {
    padding: 0 0 20px 20px;
  }
}

.heroWrapper{
  display:block;
  width: 100%;
  overflow:hidden;
  padding:0;
}

.hero {
  position: relative;
  width: 100vw;
  height: 80vh;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(./flowerHero.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: brightness(60%);
}

.hero-content {
  position: relative;
}

.contact {
  > p {
    padding: 5px 0 5px 20px;
  }
}